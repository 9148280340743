<template>
  <form @submit.prevent="submit">
    <div class="row">
      <loading-notification :show="loading || verifying" />
      <error-notification :show="!verifying && hasSelectedTeam && noTeamData">
        {{ $t("notifications.auth.team_uncompleted") }}
        <router-link
          v-if="!!selectedTeam"
          :to="{ name: 'teamsEdit', params: { id: selectedTeam.id } }"
        >
          {{ selectedTeam.code }}
        </router-link>
      </error-notification>
    </div>

    <h6>{{ $t("teams.inputs.selectTeamInut") }}</h6>
    <div class="row">
      <div class="flex xs12">
        <team-bar
          :loading="loading"
          :disabled="loading"
          :with-filters="teamFilter"
          :initial-team="initialTeam"
          @selected-team="teamSelected"
        />
      </div>
    </div>

    <hr />

    <div>
      <h6>{{ $t("teams.inputs.newTeamInput") }}</h6>
      <location-bar
        :loading="loading"
        :with-filters="locationFilters"
        :initial-district="initialDistrict"
        @selected-country="adjustCountry"
        @selected-district="adjustDistrict"
      />
      <div class="row">
        <div class="flex xs12 md4">
          <va-select
            v-model="selectedLeader"
            :label="$t('teams.inputs.leaderInput')"
            :options="leadersList"
            :loading="isLoading.leaders"
            :no-options-text="$t('layout.empty')"
            :disabled="loading || hasSelectedTeam"
            text-by="name"
            searchable
          />
        </div>
        <div class="flex xs12 md4">
          <va-input
            color="info"
            v-model="form.email.value"
            :disabled="loading"
            :label="$t('layout.form.emailInput')"
            :error="!!form.email.errors.length"
            :error-messages="translatedErrors(form.email.errors)"
            @blur="validate('email')"
          />
        </div>
        <div class="flex xs12 md4">
          <va-input
            v-model="form.code.value"
            :loading="isLoading.code"
            :disabled="loading || isLoading.code"
            :label="$t('teams.inputs.codeInput')"
            :error="!!form.code.errors.length"
            :error-messages="translatedErrors(form.code.errors)"
            @blur="validate('code')"
          />
        </div>
      </div>
      <hr />
    </div>

    <div class="row">
      <div class="flex xs12 md6">
        <va-select
          v-model="selectedLanguages"
          :label="$t('teams.inputs.languagesInput')"
          :options="LanguagesLists"
          :no-options-text="$t('layout.empty')"
          :disabled="loading"
          text-by="name"
          multiple
          searchable
        />
      </div>
      <div class="flex xs12 md6">
        <va-input
          color="info"
          v-model="form.zoneDescription.value"
          type="textarea"
          :autosize="true"
          :min-rows="5"
          :disabled="loading"
          :label="$t('teams.inputs.zoneDescriptionInput')"
          :error="!!form.zoneDescription.errors.length"
          :error-messages="translatedErrors(form.zoneDescription.errors)"
          @blur="validate('zoneDescription')"
        />
      </div>
    </div>
  </form>
</template>

<script>
import Languages from "@/data/Languages";
import { validatorMixin } from "@/services/validator";
import { mapGetters } from "vuex";

// const VueSelectImage = () => import(/* webpackPrefetch: true */ 'vue-select-image')
const LocationBar = () =>
  import(/* webpackPrefetch: true */ "@/components/extras/Bars/Location");
const TeamBar = () =>
  import(/* webpackPrefetch: true */ "@/components/extras/Bars/Team");
require("vue-select-image/dist/vue-select-image.css");

export default {
  name: "teams-form",
  components: {
    // VueSelectImage,
    LocationBar,
    TeamBar,
  },
  props: {
    loading: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    team: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
  },
  mixins: [validatorMixin],
  computed: {
    ...mapGetters(["currentUser", "currentLocale"]),
    hasSelectedTeam() {
      return !!this.selectedTeam;
    },
    notValid() {
      if (!this.selectedLanguages.length) {
        this.showToast(this.$t("layout.forms.language_needed"), {
          icon: "fa-times",
          position: "top-right",
        });
      }

      return this.noTeamData;
    },
    noTeamData() {
      return (
        this.noRegionalData ||
        !this.selectedLeader ||
        !this.form.email.value ||
        !this.selectedLanguages.length
      );
    },
  },
  data() {
    return {
      verifying: false,
      assignedError: false,
      noRegionalData: false,
      isLoading: {
        leaders: false,
        teams: false,
        code: false,
        responsible: false,
      },
      isError: {
        leaders: false,
        teams: false,
        responsible: false,
      },
      asignedPercentage: null,
      teamFilter: "&with-users=true",
      initialTeam: null,
      selectedTeam: null,
      locationFilters: ["regions", "countries", "districts"],
      selectedCountry: "",
      selectedDistrict: "",
      leadersList: [],
      responsibleList: [],
      selectedLeader: "",
      selectedResponsible: "",
      teamDateResponsible: "",
      LanguagesLists: [],
      selectedLanguages: [],
      selectedEquipement: [],
      initialDistrict: null,
      LanguagesU: [],
      cuen: [],

      form: {
        email: {
          value: "",
          validate: {
            required: true,
          },
          errors: [],
        },
        code: {
          value: "",
          validate: {
            required: true,
          },
          errors: [],
        },
        zoneDescription: {
          value: "",
          validate: {
            required: false,
          },
          errors: [],
        },
        equipment_isnotworking: {
          value: false,
          validate: {
            required: true,
          },
          errors: [],
        },
      },
    };
  },
  watch: {
    team(val) {
      this.setTeam(val);
    },
    selectedCountry(val) {
      this.checkRegionalData();
      this.checkTeamCode();
    },
    selectedDistrict(val) {
      this.checkRegionalData();
    },
    selectedLeader(val) {
      if (val.email) {
        this.setFormValue("email", val.email);
        this.validateAll();
      }
    },
    currentLocale(val) {
      this.validateAll();
    },
    "currentUser.permissions"(val) {
      this.initialData();
    },
  },
  created() {
    this.initialData();
    this.LanguagesLists = Languages.slice(0);
  },

  methods: {
    async setTeam(val) {
      this.setFormData(val);
      if (val.country) {
        this.selectedCountry = val.country;
      }
      if (val.district) {
        this.selectedDistrict = val.district;
        this.initialDistrict = this.selectedDistrict;
        if (!this.report.type_district) {
          this.report.type_district = val.district.name;
        }
      }
      if (val.leader) {
        this.selectedLeader = val.leader;
      }
      if (val.responsible) {
        this.selectedResponsible = val.responsible;
      }
      if (val.date_responsible) {
        this.teamDateResponsible = val.date_responsible;
      }

      if (val.id) {
        await this.$nextTick();
        this.validateAll();
      }
    },
    teamSelected(team) {
      if (!team) {
        this.resetTeam();
        return;
      }
      this.verifyTeam(team);
      this.selectedTeam = team;
      this.selectedTeam.status = 1; // renovado
      this.selectedDistrict = team.district;
      this.initialDistrict = this.selectedDistrict;
      this.selectedEmail = team.email;
      const lastIndex = team.teams_year_plans.length - 1;
      this.LanguagesU = team.teams_year_plans[lastIndex].languages;
      this.selectedLanguages.push(this.LanguagesU);
      this.form.zoneDescription.value =
        team.teams_year_plans[lastIndex].zoneDescription;
      this.selectedResponsible = team.responsible;
      this.teamDateResponsible = team.date_responsible;
    },
    checkRegionalData() {
      this.noRegionalData = !this.selectedCountry || !this.selectedDistrict;
    },
    async initialData() {
      await this.$nextTick();
      if (this.team) {
        this.setTeam(this.team);
      }

      if (this.currentUser.can("Users", "index")) {
        this.asyncFindLeader("5");
      }
      this.validateAll();
    },
    async verifyTeam(team) {
      this.verifying = true;

      let response = false;
      try {
        response = await this.$http.get(`teams/${team.id}`);
      } catch (e) {
        // console.log('Error fetching team', e)
        this.verifying = false;
        return;
      }

      const t = response.data.data;
      this.adjustRegionalData(t);
      this.adjustLeaderData(t);
      this.setFormValue("code", t.code);
      this.team.code = t.code;
      // console.log('value', this.getFormData(this.selectedTeam))
      this.verifying = false;
    },
    async resetTeam() {
      this.selectedCountry = null;
      this.countriesList = [];
      this.selectedDistrict = null;
      this.districtsList = [];
      this.selectedLeader = "";
      this.selectedResponsible = "";
      this.teamDateResponsible = "";
      this.leadersList = [];
      this.selectedTeam = null;
      this.setFormValue("code", null);
      this.team.code = null;
      this.team.email = null;

      await this.$nextTick();
      this.validateAll();
    },

    async checkTeamCode() {
      if (this.hasSelectedTeam || !this.selectedCountry)
        return this.setFormValue("code", this.team.code);
      this.isLoading.code = true;
      const countryId = this.selectedCountry.id;

      let response = null;
      try {
        response = await this.$http.get("/teams/last?country=" + countryId);
      } catch (e) {
        this.isLoading.code = false;
        return;
      }

      this.setFormValue("code", response.data.data);
      this.validateAll();
      this.isLoading.code = false;
    },
    adjustRegionalData(team) {
      const district = team.district;
      if (!district) return;
      const selectedDistrict = {
        id: district.id,
        name: district.name,
      };
      this.selectedDistrict = selectedDistrict;

      const country = district.country;
      if (!country) return;
      const selectedCountry = {
        id: country.id,
        name: country.name,
        region_id: country.region_id,
      };
      this.selectedCountry = selectedCountry;
    },
    async adjustLeaderData(team) {
      if (!team.leader) return;
      const selectedLeader = {
        id: team.leader_id,
        name: team.leader.name,
      };
      this.selectedLeader = selectedLeader;
      this.setFormValue("email", team.email);

      await this.$nextTick();
      this.validateAll();
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.LanguagesLists.push(tag);
      this.selectedLanguages.push(tag);
    },
    selectEquipement(data) {
      this.selectedEquipement = data;
      // console.log('selectedEquipement', data)
    },
    async asyncFindDistrict(query) {
      let route = "districts";
      if (this.selectedCountry) {
        route += "?country=" + this.selectedCountry.id;
      }

      await this.asyncFind(query, "districts", route);
      if (this.isError.districts) {
        this.asyncFindDistrict(query);
      }
    },
    async asyncFindLeader(query) {
      await this.asyncUsers(query, "leaders", "users");
      if (this.isError.leaders) {
        this.asyncFindLeader(query);
      }
    },
    async asyncUsers(query, type, route) {
      this.isLoading[type] = true;
      this.isError[type] = false;

      if (!query.includes("&sort=")) {
        query += "&sort=name";
      }
      if (!query.includes("&direction=")) {
        query += "&direction=asc";
      }

      const r = `/${route}?group=${query}`;
      let response = false;
      try {
        response = await this.$http.get(r);
      } catch (err) {
        this.isLoading[type] = false;
        this.isError[type] = false;
        return;
      }

      this[type + "List"] = response.data.data || [];
      this.isLoading[type] = false;
    },
    async asyncFind(query, type, route) {
      this.isLoading[type] = true;
      this.isError[type] = false;

      if (!query.includes("&sort=")) {
        query += "&sort=name";
      }
      if (!query.includes("&direction=")) {
        query += "&direction=asc";
      }

      let response = false;
      try {
        response = await this.$http.get(`/${route}?q=${query}`);
      } catch (err) {
        this.isLoading[type] = false;
        this.isError[type] = false;
        return;
      }

      this[type + "List"] = response.data.data || [];
      this.isLoading[type] = false;
    },
    async checkLeader(user) {
      this.verifying = true;
      this.team.email = user.email;

      let response = false;
      try {
        response = await this.$http.get(`users/ownership/${user.id}?teams`);
      } catch (e) {
        // console.log('Cant verify ownership', e)
        this.verifying = false;
        return;
      }

      this.assignedError = response.data.data;
      this.verifying = false;

      await this.$nextTick();
      this.validateAll();
    },
    adjustDistrict(district) {
      this.selectedDistrict = district;
      this.asyncFindLeader("5&district=" + district.id);
    },
    adjustCountry(country) {
      this.selectedCountry = country;
    },
    async submit() {
      this.validateAll();
      const team = this.getFormData(this.selectedTeam);
      team.equipement = this.selectedEquipement
        .map((o) => o.id)
        .sort((a, b) => a > b)
        .join();
      if (!this.formReady) return;

      if (this.selectedTeam) {
        team.id = this.selectedTeam.id;
      }

      if (this.selectedCountry) {
        team.country_id = this.selectedCountry.id;
        team.region_id = this.selectedCountry.region_id;
      }
      delete team.country;
      delete team.region;

      if (this.selectedDistrict) {
        team.district_id = this.selectedDistrict.id;
      }
      delete team.district;

      if (this.selectedLeader) {
        team.leader_id = this.selectedLeader.id;
      }

      if (this.selectedResponsible) {
        team.responsible_id = this.selectedResponsible.id;
      }

      if (this.teamDateResponsible) {
        team.date_responsible = this.teamDateResponsible;
      }
      delete team.leader;

      team.languages = this.selectedLanguages
        .map((o) => o.name)
        .sort((a, b) => a > b)
        .join();
      team.zoneDescription = team.zoneDescription || "";

      if (team.users) {
        delete team.users;
      }

      this.assignedError = false;
      this.$emit("submit", team);
    },
  },
};
</script>
<style lang="scss">
.equipement-style {
  display: flex;
  justify-content: center;

  &__wrapper {
    overflow: auto;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    margin: 0 12px 12px 0;
    float: left;

    @media only screen and (min-width: 1200px) {
      margin-left: 30px;
    }
  }

  &__thumbnail {
    padding: 6px;
    border: 1px solid #dddddd;
    display: block;
    // padding: 4px;
    line-height: 20px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    &--selected {
      background: #0088cc;
      color: white;

      .custom-style__img {
        zoom: 1.1;
      }
    }
  }

  &__img {
    -webkit-user-drag: none;
    display: block;
    width: 10vw;
    min-width: 150px;
    border-radius: 5px;
    margin-right: auto;
    margin-left: auto;
    height: 120px;
    cursor: pointer;
  }

  &__lbl {
    display: block;
    font-weight: bold;
    text-align: center;
  }
}
</style>
